import classNames from 'classnames';
import dynamic from 'next/dynamic';
import path from 'path';
import { CSSProperties, FC, Fragment, ReactNode } from 'react';

import SimpleHeader from '~/sections/Header/Simpleheader';
import { getTheme } from '~/utils/theme';

const Footer = dynamic(() => import('~/sections/Footer'), {
    ssr: true
});
const QuickCart = dynamic(() => import('~/components/QuickCart'), {
    ssr: false
});
const Newsletter = dynamic(
    () =>
        import(`~/theme/${getTheme()}/sections/NewsLetter`)
            .then((mod) => mod.default)
            .catch((error) => {
                return import(`~/sections/NewsLetter`).then((mod) => mod.default);
            }),
    {
        ssr: true,
        loading: () => null
    }
);

interface LayoutProps {
    isUseLayout?: boolean;
    children?: ReactNode;
    isUseMinHeight?: boolean;
    contentContainerStyle?: CSSProperties;
    recentKeywords?: string[];
    setRecoilRecentKeyword?: (value: string[]) => void;
    className?: string;
    renderHeader?: ReactNode;
    footer?: ReactNode;
}

const themeHeaderPath = path.join(process.cwd(), 'src/theme', getTheme(), 'sections', 'Header', 'Header.tsx');

// const Header = dynamic(() =>
//   checkFileExists(themeHeaderPath)
//     ? import(`~/theme/${getTheme()}/sections/Header`)
//     : import("~/sections/Header")
// );

const Header = dynamic(
    async () => {
        try {
            return await require(`~/theme/${getTheme()}/sections/Header`);
        } catch (error) {
            return await require(`~/sections/Header`);
        }
    },
    { ssr: false, loading: () => null }
);

const Layout: FC<LayoutProps> = ({
    isUseLayout = true,
    children,
    isUseMinHeight = true,
    contentContainerStyle,
    className = '',
    renderHeader,
    footer = <Footer />
}) => {
    if (renderHeader) {
        return (
            <Fragment>
                {renderHeader ? renderHeader : null}

                <main
                    className={classNames({ 'min-h-screen': isUseMinHeight }, className)}
                    style={contentContainerStyle}
                >
                    {children}
                </main>
                {footer}
            </Fragment>
        );
    }

    if (!isUseLayout) {
        return (
            <Fragment>
                {<SimpleHeader />}
                <main
                    className={classNames({ 'min-h-screen': isUseMinHeight }, className)}
                    style={contentContainerStyle}
                >
                    {children}
                </main>
            </Fragment>
        );
    }

    return (
        <Fragment>
            <Header />

            <main
                className={classNames({ 'min-h-screen': isUseMinHeight }, className)}
                style={{
                    ...contentContainerStyle,
                    marginBottom: '2rem'
                }}
            >
                {children}
            </main>

            {<Newsletter />}
            <QuickCart />
            {footer}
        </Fragment>
    );
};

export default Layout;
